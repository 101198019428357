import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface ProgressBar {
    className?: string;
    label?: string;
    autoStart?: boolean;
    started?: boolean;
    completed?: boolean;
    duration: number;
    preCompletionMaxPercent?: number;
    format?: 'positive' | 'negative';
}

const ProgressBar: React.FunctionComponent<ProgressBar> = (props) => {
    const {
        className,
        label,
        autoStart = false,
        duration,
        started = false,
        completed = false,
        preCompletionMaxPercent = 100,
        format = 'positive',
    } = props;

    const [startedInternal, setStartedInternal] = useState(false);

    useEffect(() => {
        setStartedInternal(started);

        return () => {
            setStartedInternal(false);
        };
    }, [started, duration]);

    useEffect(() => {
        if (autoStart) {
            const timeout = setTimeout(() => {
                setStartedInternal(true);
            }, 1);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [autoStart, duration]);

    return (
        <ProgressBarStyled className={className}>
            {label && <LabelStyled>{label}</LabelStyled>}
            <TrackStyled format={format}>
                <ProgressStyled
                    duration={duration}
                    started={startedInternal}
                    completed={completed}
                    preCompletionMaxPercent={preCompletionMaxPercent}
                    format={format}
                />
            </TrackStyled>
        </ProgressBarStyled>
    );
};

const LabelStyled = styled.div`
    ${({ theme }) => theme.typography.Heading16};
    font-size: ${rem(13)};
    margin-bottom: ${rem(8)};
`;

const ProgressStyled = styled.div<Partial<ProgressBar>>`
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: ${rem(3)};
    width: 100%;
    right: 100%;
    transform: translateX(0);
    background-color: ${({ theme, format }) =>
        format === 'positive' ? theme.palette.Green : theme.palette.Red};
    transition: transform 0s linear;

    ${({ started, preCompletionMaxPercent, duration }) =>
        started &&
        css`
            transition: transform ${duration}ms ease-in;
            transform: translateX(${preCompletionMaxPercent}%);
        `}

    ${({ completed }) =>
        completed &&
        css`
            transition: transform 250ms ease-out;
            transform: translateX(100%);
        `}
`;

const TrackStyled = styled.div<{ format: ProgressBar['format'] }>`
    height: ${rem(4)};
    width: 100%;
    border-radius: ${rem(3)};
    overflow: hidden;
    position: relative;
    background-color: ${({ theme, format }) =>
        format === 'positive' ? theme.palette.DarkGreen1 : theme.palette.RedTransparent1};
`;

const ProgressBarStyled = styled.div``;

export default ProgressBar;
