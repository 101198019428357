import React, { HTMLAttributes } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { rem, transparentize } from 'polished';

interface Pulse extends HTMLAttributes<HTMLDivElement> {
    className?: string;
    size?: number;
    format?: 'success' | 'warning' | 'error';
}

const Pulse: React.FunctionComponent<Pulse> = (props) => {
    const { className, format = 'success', size = 8, ...rest } = props;

    return <PulseStyled className={className} format={format} size={size} {...rest} />;
};

const PulseAnimation = (color: string, size: Pulse['size']) => keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${transparentize(0.7, color)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 ${rem(size)} ${transparentize(1, color)};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${transparentize(1, color)};
    }
`;

const PulseStyled = styled.div<{ format: Pulse['format']; size: Pulse['size'] }>`
    border-radius: 50%;
    margin: ${rem(4)};
    height: ${({ size }) => rem(size)};
    width: ${({ size }) => rem(size)};
    transform: scale(1);
    background: ${({ theme }) => theme.palette.Green};
    box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.Green};
    animation: ${({ theme, size }) => PulseAnimation(theme.palette.Green, size)} 2s infinite;

    ${({ theme, format, size }) => {
        switch (format) {
            case 'warning':
                return css`
                    background: ${theme.palette.Yellow};
                    box-shadow: 0 0 0 0 ${theme.palette.Yellow};
                    animation: ${PulseAnimation(theme.palette.Yellow, size)} 2s infinite;
                `;
            case 'error':
                return css`
                    background: ${theme.palette.Red};
                    box-shadow: 0 0 0 0 ${theme.palette.Red};
                    animation: ${PulseAnimation(theme.palette.Red, size)} 2s infinite;
                `;
            default:
                return css`
                    background: ${theme.palette.Green};
                    box-shadow: 0 0 0 0 ${theme.palette.Green};
                    animation: ${PulseAnimation(theme.palette.Green, size)} 2s infinite;
                `;
        }
    }}
`;

export default Pulse;
